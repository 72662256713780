.event-game {
	&.event-game-status-draft {
		background: #fbc658;
	}
	&.event-game-status-published {
		background: #6bd098;
	}
	&.event-game-status-completed {
		background: #ccc;
	}

	.event-game-code {
		font-size: 12px;
		font-weight: bold;
	}

	.event-game-title {
		font-size: 12px;
	}
}
